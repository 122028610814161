import React from "react"
import { Link } from "gatsby";
import Layout from "../components/layout"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import SEO from "../components/seo"

import './404.scss';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <Row>
        <Col className="notFoundWrap">
          <h1>Not Found</h1>
          <p>The page you are looking for does not exist, may we suggest the <Link to='/site-map/'>site map</Link> page?</p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
